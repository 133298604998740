import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import "./Bid.css";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { TokenSend, TokenBalace, GetChainId } from "../../Connection/Wallets";
import { isMobile } from "react-device-detect";
import Footer from "../Footer";
import Image23 from "../../Images/introvideo2.mp4";
import Countdown, { Countdownbiding } from "../Countdown";

const warn = (msg) => toast.error(msg);
const notify = (msg) => toast.success(msg);

const countdownDatebid = "2022-11-08 09:30";
const currnt = new Date().getTime();

function Bid({ account, url }) {
  const [bno, setBno] = useState();
  const [bidno, setBidno] = useState();
  const [amount, setAmount] = useState(0);
  const [last, setLast] = useState(0);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const [showlastbit, setShowLastbit] = useState(0);
  useEffect(() => {
    const init = async () => {
      const bal = await TokenBalace();
      setBalance(bal / 10 ** 18);
      await handleSno(0);
    };
    init();
  }, [account]);

  useEffect(() => {
    const init = async () => {
      const id = await GetChainId();
      console.log("Chain ID is ", id);
      if (Number(id) != 56) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x38" }], // chainId must be in hexadecimal numbers
        });
      }
    };

    init();
  }, []);

  const handleSno = async (num) => {
    try {
      if (Number(num) > 1000) {
        return true;
      }
      setBidno(Number(num));
      const BNO = Number(num);
      const bids = await axios
        .post(`${url}/isbid`, {
          num: BNO,
        })
        .then((res) => {
          // console.log(res);
          return res.data;
        });
      console.log(bids);
      if (bids == "") {
        setLast(0);
        setBno([]);
        return 0;
      }
      bids.Bids.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
      setLast(bids.Last);
      setBno(bids.Bids);
      setShowLastbit(bids.Last);
      if (account) {
        for (let i = 0; i < bids.Bids.length; i++) {
          console.log("checking wallet ", account, bids.Bids[i].wallet);
          if (account.toLowerCase() == bids.Bids[i].wallet.toLowerCase()) {
            console.log("Already exits wallet", account, bids.Bids[i].wallet);
            const amount = bids.Last - bids.Bids[i].amount;
            console.log(amount);
            setShowLastbit(amount);
            break;
          }
        }
      }
    } catch (error) {}
  };
  // console.log("Last bid amount",showlastbit)

  const update = async () => {
    const bids = await axios
      .post(`${url}/isbid`, {
        num: bidno,
      })
      .then((res) => {
        // console.log(res);
        return res.data;
      });
    console.log(bids);
    if (bids == "") {
      setLast(0);
      setBno([]);
      return 0;
    }
    bids.Bids.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount));
    setLast(bids.Last);
    setBno(bids.Bids);
    setShowLastbit(bids.Last);
    if (account) {
      for (let i = 0; i < bids.Bids.length; i++) {
        console.log("checking wallet ", account, bids.Bids[i].wallet);
        if (account.toLowerCase() == bids.Bids[i].wallet.toLowerCase()) {
          console.log("Already exits wallet", account, bids.Bids[i].wallet);
          const amount = bids.Last - bids.Bids[i].amount;
          console.log(amount);
          setShowLastbit(amount);
          break;
        }
      }
    }
  };

  // setInterval(async()=>{
  //  await update()
  // },2000)

  const placeBid = async () => {
    try {
      if (1667880000000 < currnt) {
        warn("Biding is over");
        return true;
      }
      if (!account) {
        warn("Wallet not detected, please refresh the page");
        return true;
      }
      if (bidno > 1000) {
        warn("Invalid bid number");
        return true;
      }
      if (Number(showlastbit) + 30 > amount) {
        warn("Entered amount should be greater than last bid amount");
        return true;
      }
      setOpen(true);

      const data = await TokenSend(amount);
      if (data.status) {
        axios
          .post(`${url}/bidon`, {
            num: bidno,
            wallet: account.toLowerCase(),
            amount: amount,
            previous: false,
          })
          .then((res) => {
            console.log(res);
            getBids();
            setOpen(false);
            notify("Bid Successfull");
          })
          .catch((e) => {
            setOpen(false);
          });
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const getBids = async () => {
    const bids = await axios
      .post(`${url}/isbid`, {
        num: bidno,
      })
      .then((res) => {
        console.log(res);
        return res.data;
      });

    setLast(bids.Last);
    setBno(
      bids.Bids.sort((a, b) => parseFloat(b.amount) - parseFloat(a.amount))
    );
    const bal = await TokenBalace();
    setBalance(bal / 10 ** 18);
    setShowLastbit(bids.Last);
    if (account) {
      for (let i = 0; i < bids.Bids.length; i++) {
        console.log("checking wallet ", bids.Bids[i].wallet);
        if (account == bids.Bids[i].wallet) {
          console.log(account, bids.Bids[i].wallet);
          const amount = bids.Last - bids.Bids[i].amount;
          console.log(amount);
          setShowLastbit(amount);
          break;
        }
      }
    }
  };

  const slice = (add) => {
    const first = add.slice(0, 4);
    const second = add.slice(37);
    return first + "..." + second;
  };

  return (
    <>
      <hr className="hr2" />
      <div style={{ backgroundColor: "white", color: "black" }}>
        <div className="container">
          <div
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="reff-id"
            sx={{
              fontSize: "1rem",
              marginBottom: "3rem",
              textAlign: "center",
              fontWeight: 800,
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div className="row">
              <div
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{
                  textAlign: "center",
                  color: "white",
                  margin: "1rem 0rem",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingBottom: "2rem",
                  }}
                >
                  {/*     <span className="mint-head">Bid cut-off time: 12 Noon, 08 Nov 2022 (Singapore GMT+08)
                  <br/>
                  Time Remaining:</span> */}
                  <span className="time1">
                    <Countdownbiding />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="row">
                <div>
                  <video loop autoPlay muted className="video">
                    <source
                      src={require("../../Images/Video23.mp4")}
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div>
                  <p className="inputtext " style={{ color: "#000" }}>
                    Last bid amount was ${isNaN(last) ? "000" : last}
                    <br />
                    <p style={{ fontSize: "13px", color: "black" }}>
                      (Minimum increment is $30 on the last highest bid amount.
                      If the last bid amount was X, then you need to bid at
                      least X + $30 or above.)
                    </p>
                  </p>
                </div>
              </div>

              <div>
                <p className="balanc" style={{ color: "#000" }}>
                  Your Balance: {isNaN(balance) ? "00" : balance} USDT
                </p>
                <input
                  type="text"
                  name="name"
                  value={bidno}
                  onChange={(e) => handleSno(e.target.value)}
                  placeholder="S. No"
                  className="text23"
                />
                {/*         <input
                  type="text"
                  name="name"
                  onChange={(e) => setAmount(Number(e.target.value))}
                  placeholder="amount"
                  className="text23"
                /> */}
              </div>
              {/*      <div>
                <button className="button1" onClick={() => placeBid()}>
                  Bid
                </button>
              </div> */}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div>
                <h3 className="text-bid2">Video guide on how to bid.</h3>
              </div>
              <div>
                <video loop autoPlay muted className="video2 video23">
                  <source src={Image23} type="video/mp4" />
                </video>
              </div>
              <div>
                <h3 className="mx-auto text-bid2">
                  List of Bidders for NFT# {bidno}
                </h3>
              </div>
              <Table striped bordered hover style={{ marginTop: "2rem" }}>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>WALLET</th>
                    {/* <th>BID TIME</th> */}
                    <th>BID AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {bno &&
                    bno.map((res) => {
                      return (
                        <tr>
                          <td>{bno.indexOf(res) + 1}</td>
                          {isMobile ? (
                            <td>{res.wallet}</td>
                          ) : (
                            <td>{res.wallet}</td>
                          )}
                          {/* <td>{new Date(res.time).toDateString()}</td> */}
                          <td>${res.amount}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <Toaster />
            </div>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <Box sx={{ display: "block", margin: "auto" }}>
            <CircularProgress color="inherit" />
            <p className="papa">Bid in process</p>
          </Box>
        </Backdrop>
      </div>
    </>
  );
}

export default Bid;
